<template>
  <div>
    <div v-if="app && config">
      <h3 class="fw-bold text-primary">Navigation</h3>

      <!--  -->
      <div class="row">
        <div class="col">
          <!--  -->

          <div class="row mb-4" v-if="app">
            <div class="col-4 my-auto">
              <h6 class="fw-bold text-primary mb-0">Nav Logo</h6>
            </div>
            <div class="col-7 my-auto">
              <div class="row">
                <div class="col-auto">
                  <div
                    v-if="!config.navLogo"
                    class="bg-light d-flex align-items-center justify-content-center p-4 h-100"
                  >
                    <div class="text-center">
                      <p class="fw-bold text-primary">Image</p>
                    </div>
                  </div>
                  <div v-if="config.navLogo">
                    <img :src="config.navLogo" :alt="app.app_title" width="75px" />
                  </div>
                </div>
                <div class="col">
                  <div class="mb-3">
                    <input class="form-control" type="file" @change="uploadNavLogo($event)" />
                    <div class="progress mt-3" style="height: 0.5rem !important">
                      <div
                        class="progress-bar"
                        role="progressbar"
                        :style="'width:' + uploadProgress + '%'"
                        :aria-valuenow="uploadProgress"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                    </div>
                  </div>

                  <button class="btn btn-sm btn-outline-danger" @click="config.navLogo = ''">
                    <i class="far fa-times me-2"></i>Clear logo
                  </button>
                </div>
              </div>
            </div>
          </div>
          <!--  -->
          <!--  -->
          <div class="row mb-4">
            <div class="col-md-4 my-auto">
              <h6 class="fw-bold text-primary mb-0">Nav Button Color</h6>
            </div>
            <div class="col-md-4 my-auto">
              <tailwind-color-selector v-model="config.navButtonBgColor"></tailwind-color-selector>
            </div>
          </div>

          <!--  -->

          <div class="row mb-4">
            <div class="col-md-4 my-auto">
              <h6 class="fw-bold text-primary mb-0">Nav Menu Background Color</h6>
            </div>
            <div class="col-md-4 my-auto">
              <tailwind-color-selector v-model="config.navBgColor"></tailwind-color-selector>
            </div>
          </div>

          <!--  -->

          <div class="row mb-4">
            <div class="col-md-4 my-auto">
              <h6 class="fw-bold text-primary mb-0">Nav Menu Text Color</h6>
            </div>
            <div class="col-md-4 my-auto">
              <tailwind-color-selector v-model="config.navTextColor"></tailwind-color-selector>
            </div>
          </div>
          <!--  -->

          <div class="row mb-4">
            <div class="col-md-4 my-auto">
              <h6 class="fw-bold text-primary mb-0">Nav Menu Text Color</h6>
            </div>
            <div class="col-md-4 my-auto">
              <select
                class="form-control"
                v-model="config.sideBarNavWidth"
                aria-placeholder="Select a width"
              >
                <option value="">Select a width</option>
                <option value="w-50">50% of the screen</option>
                <option value="w-75">75% of the screen</option>
              </select>
            </div>
          </div>
          <!--  -->

          <div class="row mb-4">
            <div class="col-md-4 my-auto">
              <h6 class="fw-bold text-primary mb-0">Sidebar Background Color</h6>
            </div>
            <div class="col-md-4 my-auto">
              <tailwind-color-selector v-model="config.sideBarNavBgColor"></tailwind-color-selector>
            </div>
          </div>
          <!--  -->

          <div class="row mb-4">
            <div class="col-md-4 my-auto">
              <h6 class="fw-bold text-primary mb-0">Sidebar Text Color</h6>
            </div>
            <div class="col-md-4 my-auto">
              <tailwind-color-selector
                v-model="config.sideBarNavTextColor"
              ></tailwind-color-selector>
            </div>
          </div>
          <!--  -->
        </div>
        <div class="col-auto ms-auto pe-5">
          <phone-simulator :src="pwaUrl"></phone-simulator>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from "@/services/firebase";
const db = firebase.firestore();
const storageRef = firebase.storage().ref();

import PhoneSimulator from "../partials/PhoneSimulator.vue";
import TailwindColorSelector from "@/views/components/colors/TailwindColorSelector";
export default {
  data() {
    return {
      app: null,
      config: null,
      uploadProgress: 0,
      file: null,
    };
  },
  computed: {
    pwaUrl() {
      return "https://pwa-demo.swandoola.com/dashboard?v=" + Date.now();
      //  return "https://pwa.swandoola.jtg:8081"
    },
  },
  watch: {
    config: {
      handler: function (val) {
        this.updateConfig();
      },
      deep: true,
    },
  },
  methods: {
    async readApp() {
      const doc = await db.collection("apps").doc("wXUPsAWUlid7azNBdZ33").get();

      const data = {
        id: doc.id,
        ...doc.data(),
      };
      this.app = data;
    },
    async updateApp() {
      console.log("updating app");
      await db.collection("apps").doc("wXUPsAWUlid7azNBdZ33").update(this.app);
    },

    async readConfig() {
      const doc = await db.collection("configs").doc("wXUPsAWUlid7azNBdZ33").get();

      const data = {
        id: doc.id,
        ...doc.data(),
      };
      this.config = data;
    },
    async updateConfig() {
      await db.collection("configs").doc("wXUPsAWUlid7azNBdZ33").update(this.config);
    },
    uploadNavLogo(event) {
      this.file = event.target.files[0];
      // Create the file metadata
      var metadata = {
        contentType: "image/jpeg",
      };

      // Upload file and metadata to the object 'images/mountains.jpg'
      var uploadTask = storageRef
        .child("apps/" + this.app.id + "/nav-logos/" + this.file.name)
        .put(this.file, metadata);

      // Listen for state changes, errors, and completion of the upload.
      uploadTask.on(
        "state_changed", // or 'state_changed'
        (snapshot) => {
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          this.uploadProgress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log("Upload is " + this.uploadProgress + "% done");
          switch (snapshot.state) {
            case "paused": // or 'paused'
              console.log("Upload is paused");
              break;
            case "running": // or 'running'
              console.log("Upload is running");
              break;
          }
        },
        (error) => {
          // A full list of error codes is available at
          // https://firebase.google.com/docs/storage/web/handle-errors
          switch (error.code) {
            case "storage/unauthorized":
              // User doesn't have permission to access the object
              break;
            case "storage/canceled":
              // User canceled the upload
              break;

            // ...

            case "storage/unknown":
              // Unknown error occurred, inspect error.serverResponse
              break;
          }
        },
        () => {
          // Upload completed successfully, now we can get the download URL
          uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
            console.log("File available at", downloadURL);
            this.config.navLogo = downloadURL;
            this.uploadProgress = 0;
            this.file = null;
          });
        }
      );
    },
  },
  created() {
    // TODO Open the sidebar in the frame?

    this.readApp();
    this.readConfig();
  },
  components: {
    PhoneSimulator,
    TailwindColorSelector,
  },
};
</script>

<style></style>
